
/***maybe refactor into BEM (Block, Elements and Modifiers) using scss.***/
html,
body {
  width: 100vw;
  min-height: 100vh;
  background-color: #000;
}

.hr1 {
  display: block;
  height: 4px;
  border: 2;
  border-top: 1px solid #a24f4f;
  margin: 1em 0;
  padding: 0;
}
.App {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;

  background-color: #000000;
  animation: fadeinApp 0.9s forwards;
  height: 100%;
}

@keyframes fadeinApp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Frame {
  display: grid;
  grid-template-columns: 25px calc(100% - 50px) 25px;
  grid-template-rows: 100px 500px auto auto 200px 100px 100px; /*400px*/
  /*600px*/
  /*Name, Picture + Data, Menu, Text, Space, Statusline, Border*/
}
.App-header {
  grid-column-start: 2;
  grid-column-end: 3;
  /*min-height: 100vh;*/
  display: block;
  color: white;
  background-color: #000;
  grid-row-start: 1;
  grid-row-end: 2;
}
.ListItem1 {
  grid-column-start: 2;
  grid-column-end: 3;

  grid-row-start: 3;
  grid-row-end: 4;
}

.active {
  color: #928ccb;
}

.LDinLogo {
  filter: invert(1);
  opacity: 80%;
  width: 30px;
}

.LDinLogo:hover {
  transform: scale(103%);
}

.DataDivWrapper {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
}
.DataDiv {
  color: white;
  font-family: sans-serif;
  vertical-align: top;
  row-gap: 5px;
  display: grid;
  align-items: start;
  font-size: 15px;
}

.TextSize {
  height: 1rem;
}

.languageMenu {
  height: 1rem;
  margin-right: 25px;
  position: absolute;
  top: 0;
  right: 0;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column: 2 3;

  z-index: 7;
}

.languageMenu li {
  margin-right: 10px;
  padding-left: 2px;
  padding-right: 2px;
}
.languageMenuliactive {
  /* background-color: #303030; */
  background: #616161;
  /* #4c87af; */

  border-radius: 2px;
}

.link_ {
  color: rgb(125, 184, 171);
}

.resize {
  width: 10rem;
  height: auto;
  float: left;
  /*animation: fadeimgin 0.1s forwards;*/
}

@keyframes fadeimgin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styleGIF {
  width: 100%;
  opacity: 0.2;
  z-index: -7;
  object-fit: cover;
  mask-clip: border-box;
  mask: linear-gradient(to left, #000, rgba(255, 255, 255, 0)); 
  /* mask: radial-gradient(circle at 90%, #000, rgba(255, 255, 255, 0)); */
}
.styleGIFPic {
  display: flex;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
}
.styleCodePic {
  margin-top: 10vh;
  opacity: 0.3;
  z-index: -6;
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 3;
  box-shadow: inset 0 0 100px #000;
  overflow: hidden;
  margin-left: 10%;
  /*mask-image: linear-gradient(to left, rgba(0, 0, 0, 1.0), transparent 100%);*/
}
.ulImpressum {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ul_ {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #303030;
  /*display: flex;*/
  flex-direction: row;
  justify-content: center;
}

li {
  float: left;
  display: inline;
}
.lia {
  display: block;
  text-align: center;
  color: rgb(239, 239, 239);
  padding: 14px 16px;
  text-decoration: none;
}

.liActive {
  display: block;
  text-align: center;
  color: rgb(239, 239, 239);
  background-color: #1c1c1c;
  padding: 14px 16px;
  text-decoration: none;
}

.lia:hover {
  background-color: #111;
  color: rgb(100, 154, 211);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: underline;
}

.Description_ {
  color: white;
  /*background-color: #0e0e0e;*/
  /*background-image: linear-gradient(to right,  #0e0e0e , rgba(0,0,0,0));*/
  -webkit-background-image: linear-gradient(to bottom, #0e0e0e, rgba(0, 0, 0, 0));
  -khtml-background-image: linear-gradient(to bottom, #0e0e0e, rgba(0, 0, 0, 0));
  -moz-background-image: linear-gradient(to bottom, #0e0e0e, rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, #0e0e0e, rgba(0, 0, 0, 0));
  padding: 6px;

  padding-bottom: 5vh;
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 3;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
